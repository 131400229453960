












import { Component, Prop } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import PieChart from '@/components/reports/PieChart.vue';
import { ChartData } from 'chart.js';
import LineDialog from '@/components/portfolio/LineDialog.vue';

@Component({
	name: 'RowChartPie',
	components: {
		PieChart,
		LineDialog,
	},
})
export default class RowChartPie extends MixinMain {
	@Prop({
		type: String,
		required: true,
	})
	readonly title!: string;
	@Prop({
		type: String,
		required: true,
	})
	readonly subtitle!: string;
	@Prop({
		type: Array,
		required: true,
	})
	readonly labels!: Array<string>;
	@Prop({
		type: Array,
		required: true,
	})
	readonly data!: Array<number>;
	
	public chartEvAudiosTip: ChartData = {
		labels: this.labels,
		datasets: [
			{
				data: this.data,
			}
		],
	};
	public extraData: Array<number> = [];
	public isLoading = false;
}
