

































































import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import RowChartPie from '@/components/main/RowChartPie.vue';
import { utils, writeFile } from 'xlsx';

@Component({
	name: 'TableAudioTags',
	components: {
		DataTable,
		RowChartPie
	},
})
export default class TableAudioTags extends MixinMain {
	public title = this.$t("MainReports.titleAudioTags");
	public subtitleAudioTags = this.$t("MainReports.subtitleAudioTags");
	public elementPerPage = this.$t("DataTable.elementPerPage");
	public countElementPage = this.$t("DataTable.countElementPage");
	public group = this.$t("chartAudioTags.table4");
	public value = '';
	public name = '';
	public isLoading = false;
	public buttonText = this.$t("ButtonDownload.exportButtonText");
	public header: Array<IHeaderTable<IDataTable>> = [
		{
			text: this.$t("chartAudioTags.titleChart") as string,
			align: 'start',
			value: 'name',
			groupable: false,
		},
		{ text: this.$t("chartAudioTags.table1") as string, align: 'end', value: 'Etiqueta'},
		{ text: this.$t("chartAudioTags.table2") as string, align: 'end', value: 'labels'},
		{ text: this.$t("chartAudioTags.table3") as string, align: 'end', value: 'total'},
	];
	public rows: Array<IDataTable> = [];
//	public footer: Array<IFootTable<IDataTable>> = [];
	public dateSelected = '';
	public items: Array<IDataTable> = [];
	public chartDisabled = false;
	public chart_key = 0;
	public subtitle_chart = '';
	public title_chart = '';
	public labels_chart: Array<string> = [];
	public data_chart: Array<number> = [];
	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
		
	}
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.getData();
			//this.getDataMalasPalabras();
		}

	}
	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(`getMotivoMora?${this.getFilterUrl}`);
		const request_2 = internet 
			.newRequest()
			.get(`getEtiqueta?${this.getFilterUrl}&Etiqueta=MalasPalabrasCX`)
		const request_3 = internet 
			.newRequest()
			.get(`getEtiqueta?${this.getFilterUrl}&Etiqueta=PalabrasProhibidasCX`)
		const request_4 = internet 
			.newRequest()
			.get(`getEtiqueta?${this.getFilterUrl}&Etiqueta=TerminosColoquialesCX`)
		
		Util.waitForPromises([request_1, request_2, request_3, request_4])
			.then((responses) => {
				const data = responses[0].data;
				//console.log(data);
				// se borra 135
				const dataTable: Array<IDataTable> = [];
				for (let item of Object.keys(data)) {
					//console.log(item);
					const row: IDataTable = {};
					row['Etiqueta'] = 'Motivo Mora';
					row['name'] = item;
					let list_labels = Object.keys(data[item].etiquetas);
					row['labels'] = list_labels.join(', ');
					row['total'] = data[item].total;
					row['data'] = data[item].etiquetas;
					dataTable.push(row);
					//this.items.push(row) se cambia 144 por esta linea
				}
				this.items = dataTable;

				// Para traer malas palabras
				const data1 = responses[1].data;
				//console.log("etiquetas", data1);
				for (let item of Object.keys(data1)) {
					const row: IDataTable = {};
					row['Etiqueta'] = 'Malas Palabras';
					row['name'] = item;
					let list_labels = Object.keys(data1[item].etiquetas);
					row['labels'] = list_labels.join(', ');
					row['total'] = data1[item].total;
					row['data'] = data1[item].etiquetas;
					this.items.push(row)
				}

				// Para traer palabras prohibidas
				const data2 = responses[2].data;
				//console.log("etiquetas", data2);
				for (let item of Object.keys(data2)) {
					const row: IDataTable = {};
					row['Etiqueta'] = 'Palabras Prohibidas';
					row['name'] = item;
					let list_labels = Object.keys(data2[item].etiquetas);
					row['labels'] = list_labels.join(', ');
					row['total'] = data2[item].total;
					row['data'] = data2[item].etiquetas;
					this.items.push(row)
				}


				// Para traer palabras prohibidas
				const data3 = responses[3].data;
				//console.log("etiquetas", data3);
				for (let item of Object.keys(data3)) {
					const row: IDataTable = {};
					row['Etiqueta'] = 'Terminos Coloquiales';
					row['name'] = item;
					let list_labels = Object.keys(data3[item].etiquetas);
					row['labels'] = list_labels.join(', ');
					row['total'] = data3[item].total;
					row['data'] = data3[item].etiquetas;
					this.items.push(row)
				}
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
				//console.log("Items: ", this.items);
			});
	}
	private getDataMalasPalabras(): void{
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(`getEtiqueta?${this.getFilterUrl}&Etiqueta=MalasPalabrasCX`);

		Util.waitForPromises([request_1])
			.then((responses) => {
				const data = responses[0].data;
				console.log("etiquetas", data);
				for (let item of Object.keys(data)) {
					//console.log(item);
					const row: IDataTable = {};
					row['Etiqueta'] = 'Malas Palabras';
					row['name'] = item;
					let list_labels = Object.keys(data[item].etiquetas);
					row['labels'] = list_labels.join(', ');
					row['total'] = data[item].total;
					row['data'] = data[item].etiquetas;
					this.items.push(row)
					//this.items.push(row) se cambia 144 por esta linea
				}
				// se borra 148
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
				//console.log("Items: ", this.items);
			});
	}

	public listenClickRow(payload: IDataTable) {
		this.title_chart = payload.Etiqueta
		this.subtitle_chart = payload.name;
		let labels = [];
		let data = [];
		let total = 0;
		for (let item of Object.keys(payload.data)) {
			labels.push(item);
			data.push(payload.data[item]);
			total += payload.data[item];
			//console.log("total", total)
		}
		data = data.map(
			function(item) {
				return (item*100)/total 	
			}
		)
		this.labels_chart = labels;
		this.data_chart = data; //(me trae la cantidad de categorias)
		this.subtitle_chart += ` (${total} audios)`;
		//console.log("item clickeado: ", payload);
		this.chart_key += 1;
		this.chartDisabled=true;
	}
	public getTextCountElementPerPage(
		to: number,
		end: number
	): string {
		let text = Util.replaceTextWith(
			this.countElementPage as string,
			'$1',
			to.toString()
		);
		text = Util.replaceTextWith(
			text,
			'$2',
			end.toString()
		);
		return text;
	}
	public descargarExcel(){
	const request = internet 
	.newRequest()
	.get("exportable/etiquetas")
	Util.waitForPromises([request])
	.then((response) => {
		console.log(response[0])
		const data_1 = response[0].data as Array<Record<string, string>>
		const order_1 = [
			'Nombre_audio',
			'Campania',
			'Agente',
			'Malas_Palabras',
			'palabras_prohibidas',
			'terminos_coloquiales',
		];
		const result_1: Array<Record<
			string,
			string
		>> = data_1.map(
			(
				obj: Record<string, string>
			): Record<string, string> => {
				const exit: Record<string, string> = {};
				for (let order of order_1) {
					exit[
						Util.replaceTextWith(
							order,
							'_',
							' '
						).toUpperCase()
					] = obj[order.toLowerCase()];
				}
				return exit;
			}
		);
		const audio = utils.json_to_sheet(result_1);
		const book = utils.book_new();
		utils.book_append_sheet(
			book,
			audio,
			'Evaluación etiquetas'
		);
		writeFile(book, 'Repositorio_etiquetas.xlsx');
	}
	)
}
}	

