








import { Component } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import FilterBarReports from '@/components/reports/FilterBarReports.vue';
import TableAudioTags from '@/components/reports/TableAudioTags.vue';

	@Component({
		name: 'MainReports',
        components: {
			AppContainerBase,
            FilterBarReports,
            TableAudioTags,
    }})
    export default class MainReports extends MixinMain {
		public title = this.$t("MainReports.title");
        public dateSelected = '';
        public noDateSelected = true;
        public dateChanged = 0;

        public changeDate(fecha:string) {
			//console.log("CAMBIANDO A ",fecha);
			this.dateSelected = fecha;
            this.noDateSelected = false;
            this.dateChanged += 1;
		}
    }

